import { IStorage } from "../_models/storage.interface";

export class LocalStorageSvc implements IStorage {
    private static instance: LocalStorageSvc;
    private constructor() { }
    public static getInstance(): LocalStorageSvc {
        if (!this.instance) {
            this.instance = new LocalStorageSvc();
        }
        return this.instance;
    }

    post<T>(id: string, data: T): void {
        localStorage.setItem(id, JSON.stringify(data));
    }

    put<T>(id: string, data: T): void {
        localStorage.setItem(id, JSON.stringify(data));
    }

    get<T>(id: string): T | null {
        const data = localStorage.getItem(id);
        if (!data) {
            return null;
        }
        return JSON.parse(data);
    }

    delete(id: string) {
        localStorage.removeItem(id);
    }

    deleteAll() {
        localStorage.clear();
    }

    deleteExcept(keepKeys: string[]): void {
        const localStorageKeys = Object.keys(localStorage);
        localStorageKeys?.forEach(key => {
            if (!keepKeys.includes(key)) {
                localStorage.removeItem(key);
            }
        });
    }

}