import { StorageProvider } from './storageProvider.service';
import { ILogoutNotifcation } from '_models/common.interface';
import { AppConstants } from '_constants/App.constants';
import { toast, TypeOptions } from 'react-toastify';

const storageSvc = StorageProvider.getStorage();

const getCurrentTimestamp = (): number => new Date().getTime();

export const saveNotification = (label: string, type: TypeOptions): void => {
    const notification: ILogoutNotifcation = { label, type, creationTs: getCurrentTimestamp(), };

    const existingNotifications = storageSvc.get<ILogoutNotifcation[]>(AppConstants.LOCAL_STORAGE_KEY.LO_NOTIFICATIONS);
    const notificationsArray: ILogoutNotifcation[] = existingNotifications ? existingNotifications : [];
    notificationsArray.push(notification);

    storageSvc.post(AppConstants.LOCAL_STORAGE_KEY.LO_NOTIFICATIONS, notificationsArray);
};

export const displayNotifications = (): void => {
    const existingNotifications = storageSvc.get<ILogoutNotifcation[]>(AppConstants.LOCAL_STORAGE_KEY.LO_NOTIFICATIONS);

    if (!existingNotifications) {
        return;
    }

    const currentTime = getCurrentTimestamp();

    existingNotifications?.forEach((notification: ILogoutNotifcation) => {
        let nType: TypeOptions = 'info';
        switch (notification.type) {
            case 'error':
                nType = 'error';
                break;
            case 'warning':
                nType = 'warning';
                break;
            case 'success':
                nType = 'success';
                break;
            default:
                nType = 'info';
        }

        if (currentTime - notification.creationTs <= 5000) {
            toast(notification.label, { type: nType });
        }
    });

    storageSvc.delete(AppConstants.LOCAL_STORAGE_KEY.LO_NOTIFICATIONS);
};
