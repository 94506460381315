import { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './i18n/i18n';
import './App.scss';
import Loading from './_pages/Loading/Loading';
import LayoutMain from './_pages/LayoutMain/LayoutMain';
import { ToastProvider } from '_contexts/ToastContext';
import { AuthSvc } from "./_services/auth.service";
import { AppConstants } from '_constants/App.constants';
import { IStorage } from '_models/storage.interface';
import { StorageProvider } from '_services/storageProvider.service';
import { BehaviorSubjectService } from '_services/behaviorSubject.service';
import PassportMissingInfo from '_pages/PassportMissingInfo/PassportMissingInfo';
import { displayNotifications } from "_services/logoutNotification.service";

const MainModuleRouter = lazy(() => import('./_modules/MainModule/MainModuleRouter'));
const VendorModuleRouter = lazy(() => import('./_modules/VendorModule/VendorModuleRouter'));
const LoginModuleRouter = lazy(() => import('./_modules/AuthModule/AuthModuleRouter'));
const AdminModuleRouter = lazy(() => import('./_modules/AdminModule/AdminModuleRouter'));
const SalesModuleRouter = lazy(() => import('./_modules/SalesModule/SalesModuleRouter'));
const BookingModuleRouter = lazy(() => import('./_modules/BookingModule/BookingModuleRouter'));
const BookingModuleContentOnlyRouter = lazy(() => import('./_modules/BookingModule/BookingModuleContentOnlyRouter'));
const CheckinOperatorModuleRouter = lazy(() => import('./_modules/CheckinOperatorModule/CheckinOperatorModuleRouter'));
const FlightDboardModuleRouter = lazy(() => import('./_modules/FlightDboardModule/FlightDboardModuleRouter'));

const App = () => {
  const authSvc = AuthSvc.getInstance();
  const storageSvc: IStorage = StorageProvider.getStorage();
  const [loader, setLoader] = useState<boolean>(false)
  const navigate = useNavigate();
  const loadingSub = BehaviorSubjectService.loadingSubject;

  useEffect(() => {
    displayNotifications();
  }, [])

  useEffect(() => {
    loadingSub.subscribe((loaderStatus: boolean) => setLoader(loaderStatus))
  }, [loadingSub])

  useEffect(() => {
    let r = window.location.href.replace(`${window.location.origin}/`, '').split('/')[0];
    // console.log(window.location.href);
    const protectedPath = ['login', ...Object.values(AppConstants.ROLES)];
    if (!protectedPath.includes(r)) return;

    let t: any = storageSvc.get(AppConstants.TOKEN.AUTH);
    let rolePath = null;
    if (t) rolePath = authSvc.extractAndDecodeTokenAndGetRolePath(t);
    if (t && r != rolePath) {
      navigate(`/${rolePath}`);
      // console.log('redirecting to ', rolePath);
    }
  }, [window.location.href])

  return (
    <>
      {!!loader &&
        <div className='cust-loader'>
          <img src='/gifs/loader.gif' alt='loading...' />
        </div>
      }
      <ToastProvider>
        <LayoutMain>
          {/* <BrowserRouter> */}
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path='/*' Component={MainModuleRouter} />
              <Route path='/sales/*' Component={SalesModuleRouter} />
              <Route element={<PrivateRoutes />}>
                <Route path='/vendor/*' Component={VendorModuleRouter} />
                <Route path='/admin/*' Component={AdminModuleRouter} />
                <Route path='/checkin/*' Component={CheckinOperatorModuleRouter} />
              </Route>
              <Route path='/login/*' Component={LoginModuleRouter} />
              {/* fsb */}
              <Route path='/fg48-info/*' Component={BookingModuleRouter} />
              {/* search-flights */}
              <Route path='/fg48/*' Component={BookingModuleContentOnlyRouter} />
              <Route path='/missing-info/*' Component={PassportMissingInfo} />
              <Route path='/upcoming-flights/*' Component={FlightDboardModuleRouter} />
            </Routes>
          </Suspense>
          {/* </BrowserRouter> */}
        </LayoutMain>
      </ToastProvider>
    </>
  );
}

export default App;


const PrivateRoutes = () => {
  const authSvc = AuthSvc.getInstance();
  return (
    authSvc.isLoggedIn() ? <Outlet /> : <Navigate to='/login' />
  )
}
