import './Loading.scss';

const Loading = () => {
  return (
    <div className='cust-loader'>
      <img src='/gifs/loader.gif' alt='loading...' />
    </div>
  );
}

export default Loading;