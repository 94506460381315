import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ITranslationResources } from '_models/translation.interface';
import translationFG48LandingEN from './locales/en/fg48-landing.json';
import translationFG48LandingFR from './locales/fr/fg48-landing.json';
import translationFsbEN from './locales/en/fsb.json';
import translationFsbFR from './locales/fr/fsb.json';

i18n
    .use(initReactI18next)
    .init<ITranslationResources>({
        resources: {
            en: { 
                fg48Landing: translationFG48LandingEN,
                fsb: translationFsbEN
            },
            fr: { 
                fg48Landing: translationFG48LandingFR,
                fsb: translationFsbFR 
            },
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
