import { LayoutProps } from '_models/common.interface';
import './LayoutMain.scss';

const LayoutMain = ({ children }: LayoutProps) => {
    return (
        <div className='layout-main-container' >
            <div className='children-container' >
                {children}
            </div>
        </div>
    )
}

export default LayoutMain;