import './PassportMissingInfo.scss';
import { useLocation, useParams } from 'react-router-dom';
import { IAddress, IMissingInfoPayload, ISegment, ITripFlightSegment, ITripPassenger, ITripRequest, SegmentActionType, TripType } from '_models/vendor.interface';
import { useEffect, useState } from 'react';
import { VendorConstants } from '_constants/Vendor.constants';
import Passenger from './Passenger';
import PassengerData from '_modules/VendorModule/_components/PassengerData/PassengerData';
import { useToast } from '_contexts/ToastContext';
import React from 'react';
import { VendorMgmntSvc } from '_services/vendorMgmnt.service';

interface IPassportMissingInfo { }

interface ISearchData {
    searchId: string;
    bookingNo: string;
}

type StatusType = 'no-update' | 'fetch-failed' | 'fetch-success' | 'submission-success';

const PassportMissingInfo: React.FC<IPassportMissingInfo> = (props: IPassportMissingInfo) => {
    const toast = useToast();
    const location = useLocation();
    const vendorMgmntSvc = VendorMgmntSvc.getInstance();

    const [passengers, setPassengers] = useState<ITripPassenger[]>([]);
    const [segments, setSegments] = useState<ISegment[]>([]);
    const [displayKey, setDisplayKey] = useState<string>('');
    const [searchData, setSearchData] = useState<ISearchData>();

    const [opStatus, setOpStatus] = useState<StatusType>('no-update');
    const [isAllfieldsValid, setIsAllfieldsValid] = useState<boolean>(true);
    const [dirtyFormFlag, setDirtyFormFlag] = useState<boolean>(false);
    const [resetFlag, setResetFlag] = useState<boolean>(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const searchId = searchParams.get('searchid');
        const bookingNo = searchParams.get('bookingNo');
        if (searchId && bookingNo) {
            setSearchData({ searchId, bookingNo });
        } else {
            setOpStatus('fetch-failed');
        }
    }, []);

    useEffect(() => {
        fetchPassengerData();
    }, [searchData])

    const fetchPassengerData = async () => {
        if (searchData?.searchId && searchData?.bookingNo) {
            vendorMgmntSvc.getMissingPassengerInfo(searchData?.searchId, searchData?.bookingNo)
                .then((res) => {
                    if (res?.data) {
                        setOpStatus('fetch-success')
                        setSegments(res.data?.segments);
                        setPassengers(res.data?.segmentWithPassengers);
                        setDisplayKey(Date.now().toString());
                    } else {
                        setOpStatus('fetch-failed');
                    }
                })
        }
    }

    const submitData = () => {
        setDirtyFormFlag(true);

        if (!checkValidity()) {
            toast(`Please fill/select all mandatory fields correctly.`, 'error');
            return;
        }

        // console.log(passengers);
        if (searchData?.searchId && searchData?.bookingNo) {
            const payload: IMissingInfoPayload = {
                bookingNo: searchData.bookingNo,
                passengers: passengers
            }
            vendorMgmntSvc.updateMissingPassengerInfo(searchData?.searchId, payload)
                .then((res) => {
                    if (res?.data) {
                        toast(`Succesfully updated information.`, 'success');
                        setOpStatus('submission-success');
                    } else {
                        toast(`Live API has returned no results. Please try again.`, 'error');
                    }
                })
        }
    }
    const checkValidity = (): boolean => {
        let isValid = true;
        for (let i = 0; i < passengers.length; i++) {
            if (!passengers[i].nationality || !passengers[i].passportNo || !passengers[i].passportIssueDate || !passengers[i].passportExpDate) {
                isValid = false;
                setIsAllfieldsValid(false);
            }
        }
        return isValid;
    }

    const resetData = () => {
        // // const defaultPassenger: ITripPassenger = JSON.parse(JSON.stringify(VendorConstants.DEFAULT_TRIP_PASSENGER));
        // const tempPassengers: ITripPassenger[] = JSON.parse(JSON.stringify(passengers));
        // tempPassengers.forEach((p: ITripPassenger) => {
        //     p.nationality = '';
        //     p.passportNo = '';
        //     p.passportIssueDate = '';
        //     p.passportExpDate = '';
        // });
        // setPassengers(tempPassengers);
        // console.log(tempPassengers);

        fetchPassengerData();

        setIsAllfieldsValid(true);
        setDirtyFormFlag(false);
        setResetFlag(true);
        setTimeout(() => {
            setResetFlag(false)
        }, 500);
    }

    const handleUpdatePassenger = (passenger: ITripPassenger, index: number) => {
        const tempPassengers: ITripPassenger[] = JSON.parse(JSON.stringify(passengers));
        if (tempPassengers?.at(index)) {
            tempPassengers[index] = passenger;
            setPassengers(tempPassengers);
        }
    }

    return (
        <div className='container-fluid passport-missing-info-container'>
            <div className='row'>

                {opStatus === 'no-update' && <div className='offset-md-2 col-md-8'>
                    <div className='message-wrapper'>
                        ...Fetching data
                    </div>
                </div>}

                {opStatus === 'fetch-failed' && <div className='offset-md-2 col-md-8'>
                    <div className='message-wrapper error-msg'>
                        <div className='message-header'>
                            Failed to fetch data.
                        </div>
                        <div className='padding-top'>
                            Please enter correct URL.
                        </div>
                    </div>
                </div>}

                {opStatus === 'fetch-success' && <>
                    <div className='col-md-12'>
                        <h2>
                            Airlines need following information to issue boarding pass
                        </h2>
                    </div>

                    <div className='col-md-12'>
                        <div className='form-wrapper'>

                            <div className='form-wrapper form-wrapper-1 booking-details'>
                                <div className='row'>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="key">
                                                    Booking Number:
                                                </td>
                                                <td className="value booking-no-value">
                                                    {searchData?.bookingNo}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="key">
                                                    Flight:
                                                </td>
                                                <td className="value">
                                                    {segments?.map((seg: ISegment, i: number) => (
                                                        <div key={i}>
                                                            {seg.airline.iata}{seg.flightnum}&nbsp;
                                                            <span className='small-text'>
                                                                ({seg.from.iata} - {seg.to.iata}, {seg.dept?.date} {seg.dept?.time})
                                                            </span>
                                                        </div>
                                                    ))}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {passengers.map((p: ITripPassenger, i: number) => (
                                <div key={i + displayKey} className='padding-top'>
                                    {/* <Passenger
                                    index={i}
                                    passenger={p}
                                    dirtyFormFlag={dirtyFormFlag}
                                    resetFlag={resetFlag}
                                    onUpdatePassenger={(passenger: ITripPassenger) => handleUpdatePassenger(passenger, i)}
                                /> */}
                                    <PassengerData
                                        index={i}
                                        tripType='B'
                                        isTripFlightWatcherOnly={false}
                                        passenger={p}
                                        dirtyFormFlag={dirtyFormFlag}
                                        resetFlag={resetFlag}
                                        missingInfoFlag={true}
                                        onPassengerSegmentChange={(type: SegmentActionType) => { }}
                                        onUpdatePassenger={(passenger: ITripPassenger) => handleUpdatePassenger(passenger, i)}
                                    />
                                </div>
                            ))}

                            <div className='padding-top'>
                                Above information are needed to issue boarding pass for your forthcoming trip.
                            </div>

                            <div className='btn-grp-wrapper padding-top'>
                                <button className='button-primary' onClick={resetData}>
                                    Reset
                                </button>
                                <button className='button-primary' onClick={submitData}>
                                    Submit
                                </button>
                            </div>

                        </div>
                    </div>
                </>}

                {opStatus === 'submission-success' && <div className='offset-md-2 col-md-8'>
                    <div className='message-wrapper message-wrapper-2 success-msg'>
                        <div className=''>
                            You will be checked in and boarding pass will be issued when airline check-in is open.
                        </div>
                        {!isAllfieldsValid && <div className='padding-top' style={{ fontWeight: 500 }}>
                            * Please note some of the information are still missing and therefore automated check-in may be affected.
                        </div>}
                        <div className='padding-top go-back-section'>
                            <div className=''>
                                Do you have more information to provide? Please, click the "Go Back" button.
                            </div>
                            <div className='button-wrapper padding-top'>
                                <button className='button-primary' onClick={resetData}>
                                    Go Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        </div>
    )
}

export default PassportMissingInfo;
