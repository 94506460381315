import { createContext, useContext } from 'react';
import { LayoutProps } from '_models/common.interface';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type ToastType = 'error' | 'success' | 'warn' | 'info';
type ToastFunctionType = (message: string, type: ToastType) => void;
const ToastContext = createContext<ToastFunctionType | null>(null);

export const useToast = () => {
    const toast = useContext(ToastContext);
    if (!toast) {
        throw new Error('useToast must be used within ToastProvider');
    }
    return toast;
};

export const ToastProvider = ({ children }: LayoutProps) => {
    const showToast: ToastFunctionType = (message: string, type: ToastType) => {
        switch (type) {
            case 'error':
                toast.error(message);
                break;
            case 'success':
                toast.success(message);
                break;
            case 'warn':
                toast.warn(message);
                break;
            case 'info':
            default:
                toast.info(message);
        }
    };

    return (
        <ToastContext.Provider value={showToast}>
            <ToastContainer theme='colored' />
            {children}
        </ToastContext.Provider>
    );
};