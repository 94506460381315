import { useState, useEffect, useRef } from 'react';
import '_modules/VendorModule/_styles/Vendor.scss';
import debounce from 'lodash/debounce';
import { useToast } from '_contexts/ToastContext';
import { UtilSvc } from '_services/util.service';
import { RefDataSvc } from '_services/refData.service';
import { IAddress, ITripPassenger, SegmentActionType, TripType } from '_models/vendor.interface';
import { ICountrySearch } from '_models/common.interface';
import { IGender } from '_models/common.interface';
import { CommonConstants } from '_constants/Common.contstants';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Tooltip } from '@mui/material';
import { FaPlusCircle, FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import PassengerAddressData from '../PassengerAddressData/PassengerAddressData';

interface ITripPassengerDataProps {
    index: number;
    singular?: boolean;
    tripType: TripType;
    isTripFlightWatcherOnly: boolean;
    passenger: ITripPassenger;
    dirtyFormFlag: boolean;
    resetFlag: boolean;
    missingInfoFlag?: boolean;
    onPassengerSegmentChange: (type: SegmentActionType) => void;
    onUpdatePassenger: (passenger: ITripPassenger) => void;
}

const PassengerData: React.FC<ITripPassengerDataProps> = (props: ITripPassengerDataProps) => {
    const { index, isTripFlightWatcherOnly, dirtyFormFlag, onPassengerSegmentChange, missingInfoFlag } = props;
    const toast = useToast();
    const utilSvc = UtilSvc.getInstance();
    const refDataSvc = RefDataSvc.getInstance();

    const [passenger, setPassenger] = useState<ITripPassenger>();
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const [countryList, setCountryList] = useState<ICountrySearch[]>([]);
    const [defaultCountryList, setDefaultCountryList] = useState<ICountrySearch[]>([]);
    const countryRef = useRef<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (props.passenger) {
                const data: ITripPassenger = props.passenger;
                if (data.phoneNumber) {
                    if (!data.phoneCode) data.phoneCode = '1';
                    setPhoneNumber(data.phoneCode + data.phoneNumber);
                }
                if (data.nationality) await updateDefaultCountryList(data.nationality);
                setPassenger(data);
            }
        }

        fetchData();
    }, [])
    useEffect(() => {
        if (passenger) props.onUpdatePassenger(passenger);
    }, [passenger]);
    useEffect(() => {
        if (props.resetFlag) {
            resetData();
        }
    }, [props.resetFlag]);

    const resetData = () => {
        // console.log("reset");
    }

    const updateDefaultCountryList = async (code: string) => {
        const res = await refDataSvc.searchCountry(code);
        if (res?.data) {
            const countryData = res.data.find((country: ICountrySearch) => [country.iso3, country.iso2].includes(code));
            if (countryData) {
                setDefaultCountryList([countryData]);
                setCountryList(res.data);
            }
        }
    }

    const handlePhoneNumberChange = (value: string, country: CountryData) => {
        if (passenger) {
            const tempPassenger: ITripPassenger = { ...passenger };
            tempPassenger.phoneCode = country.dialCode;
            tempPassenger.phoneNumber = value.substring(country.dialCode?.length);
            // console.log({code: tempPassenger.phoneCode, number: tempPassenger.phoneNumber})
            setPassenger(tempPassenger);
            setPhoneNumber(value);
        }
    }
    const handleDataChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, type: 'other' | 'checkbox' | 'date') => {
        if (passenger) {
            switch (type) {
                case 'other':
                case 'date':
                    {
                        let { name, value } = e.target;
                        // if (type === 'date') value = utilSvc.formatDate2(value, 'yyyy-mm-dd')
                        setPassenger({ ...passenger, [name]: value });
                    }
                    break;
                case 'checkbox':
                    {
                        const { name, checked } = e.target as HTMLInputElement;
                        setPassenger({ ...passenger, [name]: checked });
                    }
                    break;
            }
        }
    }

    const debouncedSearchCountries = debounce((query: string) => {
        handleSearchCountries(query)
    }, 300);
    const handleSearchCountries = (query: string) => {
        refDataSvc.searchCountry(query)
            .then((res: any) => {
                if (res?.data) {
                    setCountryList(res.data);
                } else {
                    toast('Failed to search countries. Response status is false.', 'warn');
                    console.warn(res);
                }
            })
    }
    const handleChangeCountry = (selected: ICountrySearch[]) => {
        if (passenger) {
            if (selected?.at(0)?.iso3) {
                setPassenger({ ...passenger, nationality: selected[0].iso3 });
                countryRef?.current?.blur();
            } else {
                setPassenger({ ...passenger, nationality: '' });
            }
        }
    }
    const handleBlurCountryTypeahead = () => {
        if (passenger && !passenger.nationality) {
            countryRef?.current?.clear();
        }
    }

    const handlePassengerSegmentChange = (type: SegmentActionType) => {
        if (type === 'remove' && passenger?.primary) toast('Cannot remove a primary passenger', 'error');
        else onPassengerSegmentChange(type);
    }
    const handleUpdateAddress = (address: IAddress) => {
        if (passenger) {
            const temp = { ...passenger };
            temp.address = address;
            setPassenger(temp);
        }
    }

    return (
        <>
            {
                !!passenger &&
                <div className='item-wrapper form-wrapper form-wrapper-1'>
                    <div className='row'>
                        {
                            !props.singular &&
                            <div className='col-md-12'>
                                {
                                    !missingInfoFlag ?
                                        <div className='data-header'>
                                            <div className='text-weight-m'>
                                                Passenger {props.index + 1}
                                            </div>
                                            <div className='btn-grp-wrapper add-delete-btn-grp'>
                                                <Tooltip title={`Remove passenger ${props.index + 1}`} arrow onClick={() => handlePassengerSegmentChange('remove')}>
                                                    <span><FaTimesCircle className='text-error text-l' /></span>
                                                </Tooltip>
                                                <Tooltip title={`Add passenger`} arrow onClick={() => handlePassengerSegmentChange('add')}>
                                                    <span><FaPlusCircle className='text-success text-l' /></span>
                                                </Tooltip>
                                            </div>
                                        </div> :
                                        <div className='fullname'>{passenger.firstname} {passenger.middlename} {passenger.lastname}</div>
                                }
                            </div>
                        }

                        {/* <div className='col-md-12'>
                            {passenger.id}
                        </div> */}

                        {/* {
                            !isTripFlightWatcherOnly &&
                            <div className='col-md-12' style={{ paddingBottom: '10px' }}>
                                <div className='cust-checkbox-group'>
                                    <input
                                        name='primary'
                                        type='checkbox'
                                        id={`psngr-primary-${passenger.id}`}
                                        checked={passenger.primary}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'checkbox')}
                                        disabled={isTripFlightWatcherOnly}
                                    />
                                    <label htmlFor={`psngr-primary-${passenger.id}`}>Primary</label>
                                </div>
                            </div>
                        } */}
                        {
                            passenger.primary && !isTripFlightWatcherOnly && !missingInfoFlag &&
                            <div className='col-md-12' style={{ paddingBottom: '10px' }}>
                                <div className='primary-passenger-wrapper'>
                                    <span className='icon'><FaCheckCircle /></span>
                                    <span className='text'>Primary passenger</span>
                                </div>
                            </div>
                        }

                        {!missingInfoFlag &&
                            <>
                                <div className='col-md-4'>
                                    <div className='cust-form-group'>
                                        <label htmlFor={`psngr-firstname-${passenger.id}`}>First name<b className='text-error'>*</b></label>
                                        <input
                                            name='firstname'
                                            type='text'
                                            id={`psngr-firstname-${passenger.id}`}
                                            value={passenger.firstname}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'other')}
                                            required
                                        />
                                        {
                                            (dirtyFormFlag && !passenger.firstname) &&
                                            <div className='text-error'>Please fill out the first name.</div>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='cust-form-group'>
                                        <label htmlFor={`psngr-lastname-${passenger.id}`}>Last name<b className='text-error'>*</b></label>
                                        <input
                                            name='lastname'
                                            type='text'
                                            id={`psngr-lastname-${passenger.id}`}
                                            value={passenger.lastname}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'other')}
                                            required
                                        />
                                        {
                                            (dirtyFormFlag && !passenger.lastname) &&
                                            <div className='text-error'>Please fill out the last name.</div>
                                        }
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='cust-form-group'>
                                        <label htmlFor={`psngr-email-${passenger.id}`}>Email
                                            {passenger.primary && <b className='text-error'>*</b>}
                                        </label>
                                        <input
                                            name='email'
                                            type='email'
                                            id={`psngr-email-${passenger.id}`}
                                            value={passenger.email}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'other')}
                                            required={passenger.primary}
                                        />
                                        {
                                            (dirtyFormFlag && !passenger.email && passenger.primary) &&
                                            <div className='text-error'>Please fill out the email id.</div>
                                        }
                                        {
                                            (dirtyFormFlag && passenger.email && !utilSvc.checkEmailVaildity(passenger.email)) &&
                                            <div className='text-error'>Please enter a valid email address.</div>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='cust-form-group'>
                                        <label htmlFor={`psngr-phone_number-${passenger.id}`}>
                                            Phone number
                                            {passenger.primary && passenger.phoneNumber && <b className='text-error'>*</b>}
                                        </label>
                                        <PhoneInput
                                            country={'us'}
                                            value={phoneNumber}
                                            onChange={(value: string, country: CountryData) => handlePhoneNumberChange(value, country)}
                                        />
                                        {/* {
                                            dirtyFormFlag && (!passenger.phoneCode || !passenger.phoneNumber) &&
                                            <div className='text-error' style={{ marginTop: '5px' }}>Please fill out the phone number.</div>
                                        } */}
                                        {
                                            (dirtyFormFlag && passenger.phoneCode && passenger.phoneNumber && !utilSvc.checkTripPhoneVaildity(passenger.phoneCode, passenger.phoneNumber)) &&
                                            <div className='text-error' style={{ marginTop: '5px' }}>Please enter a valid phone number.</div>
                                        }
                                    </div>
                                </div>
                                {/* <div className='col-md-4'>
                                    <div className='cust-form-group'>
                                        <label>
                                            Contact Type
                                        </label>
                                        <div className='checkbox-options-wrapper'>
                                            <span><input type='checkbox' value='wa' /> <label>WhatsApp</label></span>
                                            <span><input type='checkbox' value='sms' /> <label>SMS</label></span>
                                        </div>
                                    </div>
                                </div> */}

                            </>
                        }

                        {
                            !isTripFlightWatcherOnly &&
                            <>
                                {!missingInfoFlag &&
                                    <>
                                        <div className='col-md-4'>
                                            <div className='cust-form-group'>
                                                <label htmlFor={`psngr-gender-${passenger.id}`}>Gender<b className='text-error'>*</b></label>
                                                <select
                                                    name='gender'
                                                    id={`psngr-gender-${passenger.id}`}
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDataChange(e, 'other')}
                                                    value={passenger.gender}
                                                >
                                                    {
                                                        [...CommonConstants.GENDERS]
                                                            .map((gender: IGender) => (
                                                                <option key={`${gender.value}-${gender.label}`} value={gender.value}>
                                                                    {gender.label}
                                                                </option>
                                                            ))
                                                    }
                                                </select>
                                                {
                                                    (dirtyFormFlag && !passenger.gender) &&
                                                    <div className='text-error'>Please select the gender.</div>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='cust-form-group'>
                                                <label htmlFor={`psngr-ktn-${passenger.id}`}>KTN</label>
                                                <input
                                                    name='ktn'
                                                    type='text'
                                                    id={`psngr-ktn-${passenger.id}`}
                                                    value={passenger.ktn}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'other')}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='cust-form-group'>
                                                <label htmlFor={`psngr-dob-${passenger.id}`}>Date of birth</label>
                                                <input
                                                    name='dob'
                                                    type='date'
                                                    id={`psngr-dob-${passenger.id}`}
                                                    max={utilSvc.formatDate2((new Date()).toString(), 'yyyy-mm-dd')}
                                                    value={passenger.dob}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'date')}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className='col-md-4'>
                                    <div className='cust-form-group'>
                                        <label>Nationality (autocomplete)
                                            {missingInfoFlag && <b className='text-error'>*</b>}
                                        </label>
                                        <AsyncTypeahead
                                            ref={countryRef}
                                            filterBy={() => true}
                                            id={`psngr-nationality-${passenger.id}`}
                                            className='typeahead'
                                            isLoading={false}
                                            labelKey='name'
                                            minLength={2}
                                            onSearch={(query: string) => debouncedSearchCountries(query)}
                                            onChange={(selected: any[]) => handleChangeCountry(selected)}
                                            onBlur={() => handleBlurCountryTypeahead()}
                                            options={countryList}
                                            defaultSelected={defaultCountryList}
                                            placeholder=''
                                            renderMenuItemChildren={(country: any) => (
                                                <>
                                                    <span>{country.name}</span>
                                                </>
                                            )}
                                        />
                                        {
                                            (missingInfoFlag && dirtyFormFlag && !passenger.nationality) &&
                                            <div className='text-error'>Please fill out the nationality.</div>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='cust-form-group'>
                                        <label htmlFor={`psngr-passport-${passenger.id}`}>Passport number
                                            {missingInfoFlag && <b className='text-error'>*</b>}
                                        </label>
                                        <input
                                            name='passportNo'
                                            type='text'
                                            id={`psngr-passport-${passenger.id}`}
                                            value={passenger.passportNo}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'other')}
                                            required={missingInfoFlag}
                                        />
                                        {
                                            (missingInfoFlag && dirtyFormFlag && !passenger.passportNo) &&
                                            <div className='text-error'>Please fill out the passport number.</div>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='cust-form-group'>
                                        <label htmlFor={`psngr-passport_issue_date-${passenger.id}`}>Passport issue date
                                            {missingInfoFlag && <b className='text-error'>*</b>}
                                        </label>
                                        <input
                                            name='passportIssueDate'
                                            type='date'
                                            id={`psngr-passport_issue_date-${passenger.id}`}
                                            max={utilSvc.formatDate2((new Date()).toString(), 'yyyy-mm-dd')}
                                            value={passenger.passportIssueDate}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'date')}
                                            required={missingInfoFlag}
                                        />
                                        {
                                            (missingInfoFlag && dirtyFormFlag && !passenger.passportIssueDate) &&
                                            <div className='text-error'>Please fill out the passport issue date.</div>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='cust-form-group'>
                                        <label htmlFor={`psngr-passport_expiry_date-${passenger.id}`}>Passport expiry date
                                            {missingInfoFlag && <b className='text-error'>*</b>}
                                        </label>
                                        <input
                                            name='passportExpDate'
                                            type='date'
                                            id={`psngr-passport_expiry_date-${passenger.id}`}
                                            min={utilSvc.formatDate2((new Date()).toString(), 'yyyy-mm-dd')}
                                            value={passenger.passportExpDate}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e, 'date')}
                                            required={missingInfoFlag}
                                        />
                                        {
                                            (missingInfoFlag && dirtyFormFlag && !passenger.passportExpDate) &&
                                            <div className='text-error'>Please fill out the passport expiry date.</div>
                                        }
                                    </div>
                                </div>
                                {!missingInfoFlag &&
                                    <PassengerAddressData
                                        address={passenger.address}
                                        id={passenger.id}
                                        resetFlag={props.resetFlag}
                                        dirtyFormFlag={dirtyFormFlag}
                                        onUpdateAddress={(address: IAddress) => { handleUpdateAddress(address) }}
                                    />
                                }
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default PassengerData;