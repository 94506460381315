
import { useState, useEffect, useRef } from 'react';
import { IAddress } from '_models/vendor.interface';
import { ICountrySearch, IStateSearch } from '_models/common.interface';
import { useToast } from '_contexts/ToastContext';
import { RefDataSvc } from '_services/refData.service';
import debounce from 'lodash/debounce';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
// import { VendorConstants } from '_constants/Vendor.constants';

interface PassengerAddressDataProps {
    address: IAddress;
    id?: string;
    resetFlag: boolean;
    dirtyFormFlag: boolean;
    onUpdateAddress: (address: IAddress) => void;
}

const PassengerAddressData = (props: PassengerAddressDataProps) => {
    // const { dirtyFormFlag } = props;
    // const [address, setAddress] = useState<IAddress>({} as IAddress);
    const [address, setAddress] = useState<IAddress>(props.address);
    const [countryList, setCountryList] = useState<ICountrySearch[]>([]);
    const [stateList, setStateList] = useState<IStateSearch[]>([]);
    const [selectionsState, setSelectionsState] = useState<IStateSearch[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<ICountrySearch[]>([]);

    const addrCountryRef = useRef<any>(null);
    const stateRef = useRef<any>(null);
    const toast = useToast();
    const refDataSvc = RefDataSvc.getInstance();

    useEffect(() => {
        if (address) props.onUpdateAddress(address);
    }, [address]);

    useEffect(() => {
        if (props.address) setAddress(props.address);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (props.address) {
                const data: IAddress = JSON.parse(JSON.stringify(props.address));
                if (data.country) await updateDefaultCountryList(data.country, data);
                setAddress(data);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (countryList?.length) {
            const countryData = countryList.find((country: ICountrySearch) => [country.iso3, country.iso2].includes(props?.address?.country));
            if (countryData) {
                setSelectedCountry([countryData])
            }
        }
    }, [countryList])

    // useEffect(() => {
    //     console.log("SELECTED COUNTRY", selectedCountry)
    // }, [selectedCountry])

    const updateDefaultCountryList = async (code: string, data: IAddress) => {
        const res = await refDataSvc.searchCountry(code);
        if (res?.data) {
            const countryData = res.data.find((country: ICountrySearch) => [country.iso3, country.iso2].includes(code));
            if (countryData) {
                setCountryList(res.data);
                setStateList(countryData.states);
                const stateData = countryData.states.find((state: IStateSearch) => state.name === props.address?.state);
                if (stateData) {
                    setSelectionsState([stateData]);
                }
                const temp = JSON.parse(JSON.stringify(props.address));
                setAddress({ ...temp });
            }
        }
    }


    const handleDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (address) {
            let { name, value } = e.target;
            setAddress({ ...address, [name]: value });
        }
    }

    const debouncedSearchCountry = debounce((query: string) => {
        handleSearchNationality(query)
    }, 300);

    const handleSearchNationality = (query: string) => {
        refDataSvc.searchCountry(query)
            .then((res: any) => {
                if (res?.data) {
                    setCountryList(res?.data);
                    setStateList([]);
                    setSelectionsState([]);
                    stateRef?.current?.clear();
                } else {
                    toast('Failed to search airlines. Response status is false.', 'warn');
                    console.warn(res);
                }
            })
            .catch((err: any) => {
                toast(`${err.message}. Failed to search airlines.`, 'error');
                console.error(err);
            });
    }

    const handleChangeCountry = (selected: ICountrySearch[]) => {
        if (address) {
            if (selected?.at(0)?.iso3) {
                setAddress({ ...address, country: selected[0]?.iso3 });
                setStateList(selected[0]?.states);
                setSelectedCountry(selected)
                addrCountryRef?.current?.blur();
            } else {
                setAddress({ ...address, country: '', state: '' });
                setSelectedCountry([])
            }
        }
    }

    const handleBlurCountryTypeahead = () => {
        if (!address?.country) {
            addrCountryRef?.current?.clear();
            setStateList([]);
            setSelectionsState([]);
            stateRef?.current?.clear();
        }
    }

    const handleChangeState = (selected: IStateSearch[]) => {
        if (address) {
            setSelectionsState(selected);
            if (selected.length > 0) {
                setAddress({ ...address, state: selected[0].name });
                stateRef?.current?.blur();
            }
            else {
                setAddress({ ...address, state: '' });
            }
        }
    }

    const handleBlurState = () => {
        if (address && !address.state) {
            setAddress({ ...address, state: '' });
            setSelectionsState([]);
            stateRef.current.clear();
        }
    }

    return (
        <>
            <div className="col-md-4">
                <div className='cust-form-group'>
                    <label htmlFor={`psngr-address-line1-${props.id}`}>Address line 1</label>
                    {/* <b className='text-error'>*</b> */}
                    <input
                        name='line1'
                        type='text'
                        id={`address-line1-${props.id}`}
                        value={address?.line1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e)}
                    // required
                    />
                    {/* {
                        (!!dirtyFormFlag && !address.line1) &&
                        <div className='text-error'>Please fill out the Address line 1.</div>
                    } */}
                </div>
            </div>
            <div className="col-md-4">
                <div className='cust-form-group'>
                    <label htmlFor={`psngr-address-line2-${props.id}`}>Address line 2</label>
                    <input
                        name='line2'
                        type='text'
                        id={`address-line2-${props.id}`}
                        value={address?.line2}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e)}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className='cust-form-group'>
                    <label htmlFor={`psngr-address-city-${props.id}`}>City</label>
                    <input
                        name='city'
                        type='text'
                        id={`address-city-${props.id}`}
                        value={address?.city}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e)}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className='cust-form-group'>
                    <label>Country (autocomplete)</label>
                    <AsyncTypeahead
                        ref={addrCountryRef}
                        filterBy={() => true}
                        id={`psngr-address-country-${props.id}`}
                        className='typeahead'
                        isLoading={false}
                        labelKey='name'
                        minLength={2}
                        onSearch={(query: string) => debouncedSearchCountry(query)}
                        onChange={(selected: any[]) => handleChangeCountry(selected)}
                        onBlur={() => handleBlurCountryTypeahead()}
                        options={countryList}
                        // defaultSelected={selectedCountry}
                        placeholder=''
                        selected={selectedCountry}
                        renderMenuItemChildren={(country: any) => (
                            <>
                                <span>{country.name}</span>
                            </>
                        )}
                    />
                </div>
            </div>

            <div className='col-md-4'>
                <div className='cust-form-group'>
                    <label>State (autocomplete)</label>
                    <Typeahead
                        ref={stateRef}
                        id={`psngr-address-state-${props.id}`}
                        className='typeahead'
                        labelKey='name'
                        onChange={(selected: any[]) => handleChangeState(selected)}
                        onBlur={handleBlurState}
                        options={stateList}
                        placeholder=''
                        selected={selectionsState}
                    />
                </div>
            </div>

            <div className="col-md-4">
                <div className='cust-form-group'>
                    <label htmlFor={`psngr-address-pincode-${props.id}`}>Pincode</label>
                    <input
                        name='pincode'
                        type='text'
                        id={`address-pincode-${props.id}`}
                        value={address?.pincode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDataChange(e)}
                    />
                </div>
            </div>
        </>
    )
}

export default PassengerAddressData
