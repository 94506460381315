import { ApiService } from "./api.service";
import { UrlProviderService } from "./urlProvider.service";
import { IContactForm } from '_models/common.interface';
import { CommonConstants } from '_constants/Common.contstants';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { BookingConstants } from "_constants/Booking.contstants";
import { IProductFeature, IVendorProfile } from "_models/vendor.interface";

dayjs.extend(utc);
dayjs.extend(timezone);

export class UtilSvc extends UrlProviderService {
    private static instance: UtilSvc;
    private constructor() {
        super('api');
    }
    public static getInstance(): UtilSvc {
        if (!this.instance) {
            this.instance = new UtilSvc();
        }
        return this.instance;
    }

    private apiSvc: ApiService = ApiService.getInstance();

    convertMomentToEpochSeconds = (e: any): number => {
        // return (new Date(e.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")).getTime()) / 1000;
        return Math.floor((new Date(e.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")).getTime()) / 1000);
    }

    safePayload = (payload: any) => {
        return encodeURIComponent(JSON.stringify(payload));
    }

    removeDuplicates = (arr: any[]) => {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }

    submitContact(data: IContactForm) {
        const uri = this.generateUrl('utils/contact');
        return this.apiSvc.postCall(uri, data, 'old');
    }
    formatDate = (dateTime: string, type: 'flight'): string => {
        if (!dateTime) return 'n/a';
        switch (type) {
            case 'flight':
                const date = dateTime.split('T')[0];
                if (!date) return 'n/a';
                return dayjs(date).utc().format(BookingConstants.dateFormat2);
            default:
                return 'n/a';
        }
    }
    formatDate2(inputDate: string, type: 'yyyy-mm-dd' | 'dd/mm/yyyy' | 'en-GB'): string {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');

        switch (type) {
            case 'yyyy-mm-dd':
                return `${year}-${month}-${day}`;
            case 'dd/mm/yyyy':
                return `${day}/${month}/${year}`;
            case 'en-GB':
                return date.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                });
        }
    }
    formatDayJSDate(inputDate: dayjs.Dayjs, type: 'yyyy-mm-dd' | 'dd/mm/yyyy' | 'en-GB'): string {
        const year = inputDate.year();
        const month = `${inputDate.month() + 1}`.padStart(2, '0');
        const day = `${inputDate.date()}`.padStart(2, '0');

        switch (type) {
            case 'yyyy-mm-dd':
                return `${year}-${month}-${day}`;
            case 'dd/mm/yyyy':
                return `${day}/${month}/${year}`;
            case 'en-GB':
                return new Date(`${year}-${month}-${day}`).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                });
        }
    }
    formatTime = (dateTime: string, type: 'flight' | 'duration'): string => {
        if (!dateTime) return 'n/a';
        switch (type) {
            case 'flight':
                const time = dateTime.split('T')[1];
                if (!time) return 'n/a';
                return time?.slice(0, 5);
            case 'duration':
                if (isNaN(Number(dateTime))) return 'n/a';
                const hour = Math.floor(Number(dateTime) / (60 * 60));
                const min = Math.floor(Number(dateTime) % (60 * 60) / (60));
                return `${hour}H:${min.toString().padStart(2, '0')}M`;
            default:
                return 'n/a';
        }
    }
    formatTime2(inputTime: string): string {
        const time = new Date(`2000-01-01T${inputTime}`);
        const hours = `${time.getHours()}`.padStart(2, '0');
        const minutes = `${time.getMinutes()}`.padStart(2, '0');

        return `${hours}:${minutes}`;
    }
    secondsToHHMM = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        // const HH = String(hours).padStart(2, '0');
        const HH = String(hours);
        const MM = String(minutes).padStart(2, '0');
        return `${HH}H:${MM}M`;
    }
    formatTimestamp(timestampMilliSecs: number, tz?: 'ist' | 'utc' | 'est'): string {
        if (isNaN(timestampMilliSecs)) return '';

        let date;
        switch (tz) {
            case 'ist':
                date = dayjs(timestampMilliSecs).utc().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm')
                break;
            case 'est':
                date = dayjs(timestampMilliSecs).utc().tz('America/New_York').format('YYYY-MM-DD HH:mm')
                break;
            default:
                date = dayjs(timestampMilliSecs).utc().format('YYYY-MM-DD HH:mm')
        }

        return date;
    }

    checkEmailVaildity(inputEmail: string): boolean {
        return CommonConstants.EMAIL_REGEX.test(inputEmail);
    }
    checkPhoneVaildity(inputPhone: string): boolean {
        return CommonConstants.PHONE_REGEX.test(inputPhone);
    }
    checkTripPhoneVaildity(phoneCode: string, phoneNumber: string): boolean {
        const phoneCodePattern = /^[1-9][0-9]{0,2}$/;
        const phoneNumberPattern = /^[0-9]{4,14}$/;
        const phoneCodeTest = phoneCodePattern.test(phoneCode);
        const phoneNumberTest = phoneNumberPattern.test(phoneNumber);
        // console.log({ phoneCode, phoneNumber, phoneCodeTest, phoneNumberTest, bool: phoneCodeTest && phoneNumberTest });
        return phoneCodeTest && phoneNumberTest;
    }

    formatName(name: string, type?: 'boolean'): string {
        const isFirstCapital = (char: string) => char === char.toUpperCase();

        let modifiedName = '';
        for (let i = 0; i < name.length; i++) {
            if (isFirstCapital(name[i])) {
                if (i === 0) {
                    modifiedName += name[i].toLowerCase();
                } else {
                    modifiedName += ` ${name[i].toLowerCase()}`;
                }
            } else {
                modifiedName += name[i];
            }
        }

        if (type === 'boolean') {
            modifiedName = `Should ${modifiedName.charAt(0).toUpperCase() + modifiedName.slice(1)}`;
        } else {
            modifiedName = modifiedName.charAt(0).toUpperCase() + modifiedName.slice(1);
        }

        return modifiedName;
    }
    downloadFile = (fileUrl: string) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.click()
    }

    generateUniqueId() {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    }

    async copyText(text: string | null | undefined): Promise<boolean> {
        if (text === null || text === undefined) return false;
        try {
            await navigator.clipboard.writeText(text);
            return true;
        } catch (err) {
            return false;
        }
    };
}